export default {
  common: {
    signIn: "登录",
    home: "首页",
    aboutUs: "关于我们",
    projectsPrograms: "项目案例",
    newsEvents: "新闻活动",
    learnMore: "查看更多",
    policyGuidelines: "政策指南",
    privacyPolicy: "隐私协议",
    termsConditions: "用户协议",
    support: "用户支持",
    links: "友情链接",
    zhejiangLab: "之江实验室",
    zhijiangDevelopmentFoundation: "之江发展基金会",
    intelligentComputing: "智能计算"
  },
  aboutUs: {
    title: "关于我们",
    openSource: "开源创新",
    openScience1: "开放科学研究过程",
    openScience2: "开放科学数据",
    openScience3: "开放科学基础设施",
    openScience4: "全面的开源许可",
    theFuture: "加速科研的未来科研范式！",
    data: "数据",
    model: "模型",
    computing: "计算",
    scale: "规模 ³",
    currently: `当前，科学发现已进入AI for Science（AI4S）的新时代。数据、模型和计算的规模化发展催生了一系列科技创新。然而，要进一步扩大规模，突破量变到质变的临界状态，亟需全球资源的聚集。在这样的背景下，Zero2X应运而生。Zero2X的愿景是探索开源创新的合作机制，促使不同主体共同合作，推动开放科学基础设施的建设，从而加速科学变革与科技创新的进程。`,
    projects: "项目案例",
    resources: "开放资源",
    researchers: "参与科研人员",
    overview: "简介",
    zero2x: `Zero2X为算力、数据、模型、工具等各类资源提供一站式入口，助力各类人员高效便捷地触达“AI for Science”的各类资源和服务，促进AI时代研究效能、协作水平和科研成果质量的提升，支撑打造开放科学最佳实践。`,
    milestone: "里程碑",
    milestone2024o1: `FAST“浪人”在球状星团 M15 中发现两颗慢脉冲星`,
    milestone2024o2: "之江实验室上演AI“追星记”",
    milestone2024o3: "GeoGPT：面向地球科学家的大型语言模型系统",
    milestone2024o4: "第四届智能计算创新论坛",
    discoverMore: "了解更多"
  },
  home: {
    title1: "Build for Scientific",
    title2: "Data Connection",
    ai: {
      title: "AI驱动，加速科研进程",
      desc: "平台提供的AI工具和算法能够显著加速您的科研进程"
    },
    collaboration: {
      title: "促进跨学科合作",
      desc: "我们平台提供便捷的社区和交流环境，使得来自不同领域的专家、学者和科研人员能够进行深度合作。"
    },
    science: {
      title: "推动开放科学和知识共享",
      desc: "我们平台强调开放科学和资源共享，支持全球科研人员互相交流、分享最新的科研成果与资源。"
    },
    exploreTheZero2x: "探索zero2x",
    exploreImg1: "地球科学",
    exploreImg2: "天文科学",
    availableOpenResources: "可用的开放资源",
    ourPlatform: "我们的平台强调开放科学和资源共享",
    computing: "算力",
    data: "数据",
    models: "模型",
    tools: "工具",
    latestNews: "近期新闻活动",
    by: "来源 ：",
    zhejiangLab: "之江实验室",
    date1: "2024-10-10",
    date2: "2024-06-24",
    date3: "2023-05-29",
    latestNew1: "第四届智能计算创新论坛",
    latestNew2: "孵化洞察：生活中的大语言模型",
    latestNew3: "相聚伦敦|应该人才交流博览会",
    ourPartner: "我们的伙伴",
    ourPartnerDesc: "在开放科学的道路上共进"
  },
  newsEvents: {
    title: "新闻活动",
    viewMore: "查看详情",
    title1: 'FAST寻获两颗球状星团长周期脉冲星',
    intro1: '球状星团是一种受自引力束缚的高密度恒星集群，其演化年龄一般超过百亿年，是宇宙中最古老的天体系统之一。球状星团中的恒星经过数十亿年的演化，存在着大量脉冲星，通过了解其性质，可获取球状星团的动力学演化过程、星族合成路径等信息。“中国天眼”FAST是世界最大的单口径射电望远镜，其历史性高灵敏度可对球状星团开展最深的射电脉冲星搜寻和观测研究。',
    title2: '科技日报 | 之江实验室上演AI“追星记”',
    intro2: '最近一年，之江实验室天文计算研究中心基于FAST的观测数据共发现了31颗脉冲星，其中包括15颗长周期脉冲星。',
    title3: '3个月→2周！“AI+生命科学”，之江实验室为病毒检测按下“加速键”',
    intro3: '得益于实验室生命科学计算开放平台最新推出的CRISPR核酸检测设计算法模型，黄柏成及团队成员将试剂盒的研发周期从3个月以上……',
    title4: '成功捕捉喷流周期性进动 M87黑洞最新研究成果登上Nature',
    intro4: '9月27日，《自然》（Nature）杂志发布由45个机构组成的国际科研团队的最新研究成果。通过分析2000年至2022年期间的观测数据，发现M87星系中心黑洞喷流呈现周期性摆动，摆动周期约为11年，振幅约为10度。',
    title7: '深度挖掘中国天眼观测数据 计算天文最新成果登上Science Bulletin封面',
    intro7: '12月26日，之江实验室智能计算平台研究中心研究专家冯毅等科研人员的最新研究成果作为封面文章发表于“中国科技期刊卓越行动计划”综合性领军期刊Science Bulletin上。该成果依托之江实验室联合中科院国家天文台打造的智能计算天文平台，在快速射电暴和脉冲星搜寻探索模型及天文数据处理模型的基础上，利用数据挖掘、机器学习、先进计算等智能计算技术对中国天眼FAST的数据进行了深度挖掘。',
  },
  privacyPolicy: {
    title: "隐私协议"
  },
  projectsPrograms: {
    title: "项目案例",
    subhead1: "一个开源的、非营利性的地球科学探索研究项目",
    intro1: "GeoGPT 是由之江实验室开发的一个开源、非营利的全球地球科学研究探索性项目。它提倡合作、共享和共建的开放科学理念。通过汇集地球科学家、人工智能专家和更广泛研究社区的力量，GeoGPT 致力于开辟新的探索途径，加速突破性发现。",
    subhead2: "以先进的人工智能能力为天文学和天体物理研究赋能",
    intro2: "AstroOne 是一个开源的非营利性天文学和天体物理学研究探索性研究项目，为天文学家提供新颖的 LLM 增强功能和工具。来自国家天文台、中国科学院和浙江实验室的数百名人工智能和天文学专家参与了 AstroOne 原型的开发。",
    viewMore: "查看更多"
  },
  termsConditions: {
    title: "用户协议"
  }
};

