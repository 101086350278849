<template>
  <div class="banner">
    <div class="spline-wrapper">
      <map-bg></map-bg>
    </div>
    <div class="banner-content">
      <div class="title">
        {{ $t('home.title1') }}<br>
        {{ $t('home.title2') }}
        <!-- Build for Scientific Data Connection -->
        <!-- <img class="line-img" src="./img/line.svg" alt="" /> -->
      </div>
      <div class="description">
        It is a software through which you can easily make the
        <br />
        right decision by analyzing the data. Data analysis is
        <br />
        important in business.
      </div>
      <button class="btn">Participate</button>
    </div>
  </div>
</template>

<script>
import MapBg from "@/views/echarts/MapBg.vue";
export default {
  name: "HomeBanner",
  components: {
    MapBg,
  },
};
</script>
<style lang="scss" scoped>
.banner {
  position: relative;
  aspect-ratio: 1920/1080;
  box-sizing: border-box;
  background: transparent;
  //background: url("./img/banner-bg.png") no-repeat;
  //background-size: cover;
  //background-color: #fff;

  .banner-content {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      font-family: Plus Jakarta Sans;
      line-height: 120px;
      max-width: 100vw;
      font-size: 90px;
      font-weight: bold;
      //color: #12141d;
      color: #fff;
      position: relative;
      text-shadow: 0 2px 5px black;

      .line-img {
        position: absolute;
        width: 501.8px;
        left: 50%;
        margin-left: -298.9px;
        top: 106px;
      }

      @media (max-width: 576px) {
        & {
          zoom: 0.5;
        }
      }
    }
    .description {
      display: none;
      margin: 25px 0;
      line-height: 28px;
      font-size: 18px;
      //color: rgba(18, 20, 29, 0.7);
      color: #fff;
    }
    .btn {
      display: none;
      width: 213px;
      height: 51px;
      outline: none;
      background: #2e4fff;
      text-align: center;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
    }
  }
  .spline-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: url("./img/map-bg.jpg") no-repeat;
    background-size: cover;
  }
}
</style>
