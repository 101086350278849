<template>
  <div>
    <div ref="chart" class="chart-box"></div>
  </div>
</template>
<script>
// 引入 ECharts 主模块
import * as echarts from "echarts/core";
import {
  MapChart,
  BarChart,
  LinesChart,
  ScatterChart,
  EffectScatterChart,
} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  GraphicComponent,
  VisualMapComponent,
  GeoComponent,
} from "echarts/components";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { nameMap, geoMap } from "./nameMap.js";
import axios from "axios";
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  MapChart,
  LinesChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  GraphicComponent,
  VisualMapComponent,
  GeoComponent,
  BarChart,
  ScatterChart,
  EffectScatterChart,
]);

export default {
  name: "MapBg",
  data() {
    return {
      flag: true,
      myChart: undefined,
      lineData: [
        ["俄罗斯", "中国"],
        ["印度", "中国"],
        ["马来西亚", "中国"],
        ["菲律宾", "中国"],
        ["新加坡", "中国"],
        ["泰国", "中国"],
        ["越南", "中国"],
        ["美国", "中国"],
        ["加拿大", "中国"],
        ["法国", "中国"],
        ["德国", "中国"],
        ["英国", "中国"],
        ["意大利", "中国"],
        ["西班牙", "中国"],
        ["澳大利亚", "中国"],
        ["巴西", "中国"],
        ["南非", "中国"],
        ["墨西哥", "中国"],
        ["阿根廷", "中国"],
        ["智利", "中国"],
      ],
      lineData2: [
        ["菲律宾", "美国"],
        ["加拿大", "美国"],
        ["法国", "美国"],
        ["德国", "美国"],
        ["英国", "美国"],
        ["意大利", "美国"],
        ["西班牙", "美国"],
        ["澳大利亚", "美国"],
        ["南非", "美国"],
        ["墨西哥", "美国"],
        ["阿根廷", "美国"],
        ["智利", "美国"],
        ["俄罗斯", "美国"],
        ["印度", "美国"],
        ["马来西亚", "美国"],
        ["新加坡", "美国"],
        ["泰国", "美国"],
        ["越南", "美国"],
        ["中国", "美国"],
      ],
      lineData3: [
        ["菲律宾", "加拿大"],
        ["德国", "加拿大"],
        ["英国", "加拿大"],
        ["意大利", "加拿大"],
        ["西班牙", "加拿大"],
        ["澳大利亚", "加拿大"],
        ["南非", "加拿大"],
        ["墨西哥", "加拿大"],
        ["阿根廷", "加拿大"],
        ["智利", "加拿大"],
        ["俄罗斯", "加拿大"],
        ["印度", "加拿大"],
        ["马来西亚", "加拿大"],
        ["新加坡", "加拿大"],
        ["泰国", "加拿大"],
        ["越南", "加拿大"],
        ["中国", "加拿大"],
      ],
    };
  },
  methods: {
    convertData(data) {
      let res = [];
      for (let i = 0; i < data.length; i++) {
        let dataItem = data[i];
        let fromCoord = geoMap[dataItem[0]];
        let toCoord = geoMap[dataItem[1]];
        if (fromCoord && toCoord) {
          res.push({
            fromName: dataItem[0],
            toName: dataItem[1],
            coords: [fromCoord, toCoord],
          });
        }
      }
      return res;
    },
    convertScatterData(data = [], index = 0) {
      const dataNumMap = {};
      for (const item of data) {
        dataNumMap[item[index]] = (dataNumMap[item[index]] ?? 0) + 1;
      }
      const res = [];
      for (let name in dataNumMap) {
        const geoCoord = geoMap[name];
        if (geoCoord)
          res.push({
            name,
            value: geoCoord.concat(dataNumMap[name]),
            itemStyle: { color: "#2E4FFF" },
          });
      }
      return res;
    },
    setOption() {
      const _this = this;
      const config = {
        visualMap: {
          show: false,
          min: 0,
          max: 100,
          inRange: {
            color: ["#A2E9FF", "#3988FF"],
          },
          top: 180,
          right: 30,
          itemWidth: 10,
          textStyle: {
            color: "#ffffff",
          },
        },
        series: [
          {
            type: "map",
            aspectScale: 1, // 横向拉伸
            map: "world",
            itemStyle: {
              areaColor: "#6f9fec", // 地图本身的颜色
              borderColor: "#0A3768", // 省份边框颜色
              borderWidth: 1, // 省份边框宽度
              opacity: 1, // 图形透明度
            },
            emphasis: {
              disabled: true,
            },
            select: {
              disabled: true,
            },
            // 若出现label偏移情况可检查world.js中相应国家的cp属性值
            labelLayout: {
              hideOverlap: true,
            },
            top: 30,
            bottom: 30,
            left: 30,
            right: 30,
          },
          {
            name: "飞线",
            type: "lines",
            zlevel: 1,
            effect: {
              show: true,
              period: 6, // 特效动画的时间，单位为 s
              trailLength: 0.3,
              color: "#F3D467",
              symbolSize: 3,
              symbol: "arrow",
            },
            label: {
              show: true,
            },

            data: _this.convertData(_this.lineData),
          },
          {
            name: "飞线",
            type: "lines",
            zlevel: 1,
            effect: {
              show: true,
              period: 6, // 特效动画的时间，单位为 s
              trailLength: 0.3,
              color: "#F3D467",
              symbolSize: 3,
              symbol: "arrow",
            },
            label: {
              show: true,
            },

            data: _this.convertData(_this.lineData2),
          },
          {
            name: "飞线",
            type: "lines",
            zlevel: 1,
            effect: {
              show: true,
              period: 6, // 特效动画的时间，单位为 s
              trailLength: 0.3,
              color: "#F3D467",
              symbolSize: 3,
              symbol: "arrow",
            },
            label: {
              show: true,
            },

            data: _this.convertData(_this.lineData3),
          },
          {
            name: "气泡",
            type: "scatter",
            coordinateSystem: "geo",
            data: _this.convertScatterData(_this.lineData, 0),
            encode: { value: 2 },
            symbolSize: (val) => val[2] * 10,
            animationEasing: "bounceOut",
            animationDuration: 3000,
          },
          {
            name: "气泡",
            type: "scatter",
            coordinateSystem: "geo",
            data: _this.convertScatterData(_this.lineData2, 0),
            encode: { value: 2 },
            symbolSize: (val) => val[2] * 10,
            animationEasing: "bounceOut",
            animationDuration: 3000,
          },
          {
            name: "气泡",
            type: "scatter",
            coordinateSystem: "geo",
            data: _this.convertScatterData(_this.lineData3, 0),
            encode: { value: 2 },
            symbolSize: (val) => val[2] * 10,
            animationEasing: "bounceOut",
            animationDuration: 3000,
          },
          // {
          //   name: "涟漪气泡",
          //   type: "effectScatter",
          //   coordinateSystem: "geo",
          //   data: _this.convertScatterData(_this.lineData, 1),
          //   encode: { value: 2 },
          //   symbolSize: (val) => val[2],
          //   rippleEffect: { brushType: "stroke" },
          //   animationEasing: "cubicOut",
          //   animationDelay: 1500,
          //   animationDelayUpdate: 1500,
          //   animationDuration: 2000,
          //   animationDurationUpdate: 2000,
          // },
        ],
        geo: {
          show: false,
          map: "world",
          top: 30,
          bottom: 30,
          left: 30,
          right: 30,
        },
        // 设置动画时间
        animationDuration: 3000, // 动画持续时间，单位为 ms
      };
      this.myChart?.setOption(config, true);
      setTimeout(() => {
        _this.myChart.clear();
        _this.setOption();
      }, 6000);
    },
    async loadJsonAsMap(url) {
      try {
        // 发送GET请求获取数据
        const response = await axios.get(url);
        const data = response.data;
        // 将对象转换为Map
        return data;
      } catch (error) {
        console.error("Error loading JSON data:", error);
        throw error;
      }
    },
  },
  mounted() {
    this.$nextTick(async () => {
      const mapData = await this.loadJsonAsMap("/world.json");
      mapData.features.forEach((item) => {
        item.properties.name =
          nameMap[item.properties.name] || item.properties.name;
      });
      echarts.registerMap("world", { geoJSON: mapData });

      const chartDom = this.$refs.chart;
      this.myChart = echarts.init(chartDom);
      this.setOption();
      window.addEventListener("resize", () => {
        this.myChart?.resize();
      });
    });
  },
};
</script>
<style lang="scss" scoped>
.chart-box {
  width: calc(100% - 1px);
  aspect-ratio: 1920/1080;
}
</style>
