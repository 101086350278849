import Vue from "vue";
import VueI18n from "vue-i18n";
//引入自定义语言配置
import zh from "@/i18n/_langs/zh";
import en from "@/i18n/_langs/en";

Vue.use(VueI18n); // 全局注册国际化包

const LANGUAGE = 'Accept-Language'
const LANGUAGE_KEY = "lang";
// const getLanguage = () => localStorage.getItem(LANGUAGE_KEY) || navigator.language.slice(0,2);
const getLanguage = () => {
  // 1. 优先使用 VUE_APP_DEFAULT_LANG
  if (process.env.VUE_APP_DEFAULT_LANG) {
    return process.env.VUE_APP_DEFAULT_LANG
  }

  // 2. 其次使用 localStorage 存储的语言
  const storedLanguage = localStorage.getItem(LANGUAGE_KEY)
  if (storedLanguage) return storedLanguage

  // 3. 最后使用浏览器语言
  return navigator.language.slice(0,2)
}
const setLanguage = language => {
  localStorage.setItem(LANGUAGE_KEY, language);
  location.reload();
}

// 准备翻译的语言环境信息
const i18n = new VueI18n({
  locale: getLanguage(),
  messages: { zh, en },
  silentTranslationWarn: true,
  globalInjection: true,
  fallbackLocale: process.env.VUE_APP_DEFAULT_LANG || 'en'
});

export default i18n;

export { getLanguage, setLanguage, LANGUAGE };

console.log(navigator.language);
