<template>
  <span>
    <!-- 未登录状态 -->
    <button v-if="!isLogin" class="sign-in-btn" @click="login">{{ $t('common.signIn')}}</button>

    <!-- 已登录状态 -->
    <span v-else class="login" :class="['Personal', 'ChangePassword'].includes($route.name) ? 'active' : ''"
          @click="$router.push({ name: 'Personal', params: { isHidenNav: true } })">
      <img :src="avatar" alt="" style="width: 36px; height: 36px; border-radius: 18px;">
      {{ user?.username }}
      <!--<b-nav-item-dropdown-->
      <!--  :text="user.username || 'User'"-->
      <!--  right-->
      <!--  class="user-item"-->
      <!--&gt;-->
      <!--  <b-dropdown-item @click="logout">退出</b-dropdown-item>-->
      <!--</b-nav-item-dropdown>-->
    </span>
  </span>
</template>

<script>
import { ajax, getParam } from "./method-util.js";
import avatar from "./avatar.png";

export default {
  name: "CommonLoginBar",
  data() {
    return {
      isLogin: false,
      code: "",
      back: "",
      user: {},
      loginUrl: ""
    };
  },
  computed: {
    avatar() {
      return this.user?.avatar ? "data:image;base64," + this.user.avatar : avatar;
    }
  },
  methods: {
    async init() {
      await this.loadInfo();
      // 如果已登录，则不处理
      if (this.isLogin) {
        return;
      }
      // 进行登录相关处理
      this.back = getParam("back") || this.$route.query.back;
      this.code = getParam("code") || this.$route.query.code;
      if (this.code) {
        this.doLoginByCode(this.code);
      } else {
        this.goSsoAuthUrl();
      }
    },
    loadInfo() {
      ajax(
        "/getCurrInfo",
        {},
        (res) => {
          console.log("/getCurrInfo 返回数据：", res);
          if (res.code === 200 && res.data) {
            this.isLogin = true;
            this.user = res.data;
          } else if (res.code === 401) {
            this.isLogin = false;
          } else {
            this.isLogin = false;
            console.log("获取登录信息失败", res.msg);
          }
        },
        true
      );
    },

    // 重定向至认证中心
    goSsoAuthUrl() {
      ajax("/sso/getSsoAuthUrl", { clientLoginUrl: location.href }, (res) => {
        console.log("/sso/getSsoAuthUrl 返回数据", res.data);
        this.loginUrl = res.data;
      });
    },

    // 根据code值登录
    doLoginByCode(code) {
      ajax("/sso/doLoginByCode", { code: code }, (res) => {
        console.log("/sso/doLoginByCode 返回数据", res);
        if (res.code === 200) {
          localStorage.setItem("portaltoken", res.data);
          this.removeQueryParam("code");
          if (this.back) {
            // 跳转回跳回地址
            location.href = decodeURIComponent(this.back);
          } else {
            // 获取用户信息
            this.loadInfo();
          }
        } else {
          console.log("登录失败", res.msg);
          alert("Login failed");
        }
      });
    },
    login() {
      location.href = this.loginUrl;
    },
    removeQueryParam(paramName) {
      const search = window.location.search;
      const urlParams = new URLSearchParams(search);
      urlParams.delete(paramName);
      const newSearch = urlParams.toString();
      const newUrl = `${window.location.pathname}${
        newSearch ? "?" + newSearch : ""
      }`;
      window.history.replaceState(null, "", newUrl);
    },
    logout() {
      ajax("/logout", {}, (res) => {
        if (res.code === 200) {
          localStorage.removeItem("portaltoken");
          this.$bvToast.toast("Logout successful.", {
            title: "Message", // 标题
            autoHideDelay: 5000, // 自动隐藏的延迟时间（毫秒）
            variant: "success", // 颜色变体
            solid: true // 是否为实心背景
          });
          setTimeout(() => {
            console.log("logout res.data", res.data);
            if (res.data) {
              location.href = res.data + "?redirect_uri=" + location.href;
            } else {
              location.reload(true);
            }
          }, 500);
        } else {
          alert(res.msg);
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.lang-img {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  top: 2px;
}

.lang-item {
  display: none;
}

.user-item {
  display: inline-block;
}

.sign-in-btn {
  height: 36px;
  width: 92px;
  background-color: transparent;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 500;
  text-align: center;
  font-size: 16px;
  color: #000;
  border: 1px solid rgba(32, 31, 34, 0.2);
  margin-left: 28px;

  &:hover {
    background-color: #2e4fff; /* Change to hover background color */
    color: #fff;
    border-color: #2e4fff;
  }
}

.login {
  cursor: pointer;

  &:hover {
    color: #007EFF;
  }

  &.active {
    color: #007EFF;

    img {
      padding: 1px;
      border-style: solid;
    }
  }
}
</style>
