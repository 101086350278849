export default {
  common: {
    signIn: "Sign in",
    home: "Home",
    aboutUs: "About Us",
    projectsPrograms: "Projects and Programs",
    newsEvents: "News & Events",
    learnMore: "Learn more",
    policyGuidelines: "Policy Guidelines",
    privacyPolicy: "Privacy Policy",
    termsConditions: "Terms & Conditions",
    support: "Support",
    links: "Links",
    zhejiangLab: "Zhejiang Lab",
    zhijiangDevelopmentFoundation: "Zhijiang Development Foundation",
    intelligentComputing: "Intelligent Computing"
  },
  aboutUs: {
    title: "ABOUT US",
    openSource: "Open Resource Innovation",
    openScience1: "Open Science \n research process",
    openScience2: "Open Science \n data",
    openScience3: "Open Science \n infrastructures",
    openScience4: "Comprehensive \n open-source licensing",
    theFuture: "The Future Paradigm Accelerating Research!",
    data: 'Data',
    model: 'Model',
    computing: 'Computing',
    scale: 'Scale³',
    currently: `Currently, scientific discovery has entered a new era of AI for Science (AI4S). The large-scale development of
            data, models, and computing has led to a series of technological innovations. However, to further scale up and
            break through the critical transition from quantitative change to qualitative change, it is essential to
            gather global resources. In this context, zero2x was established. The vision of zero2x is to explore
            cooperative mechanisms for open-source innovation, enabling various stakeholders to collaborate and promote
            the construction of open science infrastructure, thereby accelerating scientific transformation and
            technological innovation.`,
    projects: 'Projects and programs',
    resources: 'Resources for open science',
    researchers: 'Researchers for open science',
    overview: "Overview",
    zero2x: `zero2x provides a one-stop portal for resources such as computing power, data, models, and tools,
              enabling various users to efficiently and conveniently access 'AI for Science' resources and services.
              This will enhance research efficiency, collaboration, and the quality of scientific outcomes in the AI
              era, supporting us in creating best practices for open science.`,
    milestone: "Milestone",
    milestone2024o1: `A Discovery of Two Slow Pulsars with FAST "Ronin" from the Globular Cluster M15`,
    milestone2024o2: 'Science and Technology Daily | ZJ Lab\'s AI-Powered Star Searches',
    milestone2024o3: 'GeoGPT: A Large Language Model System for Geoscientists',
    milestone2024o4: 'The 4th Innovation Forum on Intelligent Computing',
    discoverMore: "Discover More"
  },
  home: {
    title1: "Build for Scientific",
    title2: "Data Connection",
    ai: {
      title: "AI-Driven,Accelerating the Research Process",
      desc: "The AI tools and algorithms provided by the platform can significantly accelerate your research process."
    },
    collaboration: {
      title: "Facilitating Interdisciplinary Collaboration",
      desc: "Our platform offers an easy-to-use community and communication space that allows you to collaborate with experts, scholars, and researchers from various fields."
    },
    science: {
      title: "Promoting Open Science and Knowledge Sharing",
      desc: "Our platform emphasizes open science and resource sharing, supporting global researchers in exchanging and sharing the latest research findings and resources."
    },
    exploreTheZero2x: "Explore the zero2x",
    exploreImg1: "Earth Science",
    exploreImg2: "Astronomy/Astronomical Science",
    availableOpenResources: "Available Open Resources",
    ourPlatform: "Our platform emphasizes open science and resource sharing",
    computing: "Computing",
    data: "Data",
    models: "Models",
    tools: "Tools",
    latestNews: "Latest News",
    by: "By ",
    zhejiangLab: "ZHEJIANG LAB",
    date1: "10 October 2024",
    date2: "26 June 2024",
    date3: "29 May 2023",
    latestNew1: "The 4th Innovation Forum on Intelligent Computing",
    latestNew2: "Hatching insights: Large language models in life …",
    latestNew3: "Gather in London | UK Talents Exchange Fair In…",
    ourPartner: "Our Partner",
    ourPartnerDesc: "Continue to work hard and move forward together on the road to open science"
  },
  newsEvents: {
    title: "NEWS & EVENTS",
    viewMore: "View More",
    title1: 'A Discovery of Two Slow Pulsars with FAST "Ronin" from the Globular Cluster M15',
    intro1: 'Globular clusters are gravitationally bound dense stellar clusters, with an evolutionary age generally exceeding 10 billion years, making them one of the oldest celestial systems in the universe.',
    title2: 'Science and Technology Daily | ZJ Lab\'s AI-Powered Star Searches',
    intro2: 'Located in Pingtang, Guizhou Province, the Five-hundred-meter Aperture Spherical Radio Telescope (FAST), also known as "China\'s Sky Eye", continuously receives radio signals from the depths of the universe.',
    title3: 'Shortening R&D Time from 3 Months to 2 Weeks! "AI + Life Sciences" Enables ZJ Lab to Find Viruses Faster',
    intro3: '"HPV may cause cancer", "About 98% of cervical cancer cases among women in China are caused by high-risk HPVs", and "There is currently no specific medicine for HPV infection"...',
    title4: 'The Periodic Jet Precession Is Successfully Captured, and the Latest Research Findings on M87',
    intro4: 'On September 27, 2023, Nature released the latest research findings of an international research team composed of 45 institutions.',
    title6: 'First Revealing of Continuously-active Repeating Fast Radio Burst in Science',
    intro6: 'On May 12, 2023, a major discovery in the field of fast radio bursts, the Magnetic Field Reversal in the Turbulent Environment Around a Repeating Fast Radio Burst, was published in Science, a top international academic journal',
    title7: 'Deep Mining of China\'s FAST Observation Data | the Latest Results of Computational Astronomy Were… ',
    intro7: 'On December 26, FENG Yi, research expert at the Research Center for Intelligent Computing Platforms of Zhejiang Lab, and other researchers published their latest research results in the cover article of the Science Bulletin',
  },
  privacyPolicy: {
    title: "Privacy Policy"
  },
  projectsPrograms: {
    title: "PROJECTS & PROGRAMS",
    subhead1: 'An Open-Source and Non-Profit Exploratory Research Project',
    intro1:'GeoGPT, an exploratory geoscience project, aims to develop open-source, non-profit AI for novel perspectives & tools in global geoscience research.',
    subhead2: 'Empowering Astronomical and Astrophysical Research with Advanced AI Capabilities',
    intro2: 'AstroOne is an open-source, non-profit exploratory research project for astronomical and astrophysical research, offering novel LLM-augmented capabilities and tools for astronomers. Hundreds of AI and astronomy experts from National Astronomical Observatories, Chinese Academy of Sciences and Zhejiang Lab have participated in the development of AstroOne prototype.',
    viewMore: 'View More'
  },

  termsConditions: {
    title: "Terms & Conditions"
  }
};
