import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/home/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/privacy-policy/PrivacyPolicyView.vue"
      ),
  },
  {
    path: "/terms-conditions",
    name: "terms-conditions ",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/terms-conditions/TermsConditionsView.vue"
      ),
  },
  {
    path: "/about-us",
    name: "about-us",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/about-us/AboutUsView.vue"
      ),
  },
  {
    path: "/projects-programs",
    name: "projects-programs",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/projects-programs/ProjectsProgramsView.vue"
      ),
  },
  {
    path: "/resource-hub",
    name: "resource-hub",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/resource-hub/ResourceHubView.vue"
      ),
  },
  {
    path: "/news-events",
    name: "news-events",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/news-events/NewsEventsView.vue"
      ),
  },
  {
    path: "/news-events/:id",
    name: "news-events-detail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/news-events/NewsEventsDetail.vue"
      ),
  },
  {
    path: "/personal",
    name: "Personal",
    component: () => import("../views/personal/index.vue"),
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () => import("../views/personal/ChangePassword.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: () => import("@/views/echarts/MapBg.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
