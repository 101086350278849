<template>
  <div class="master-view">
    <h2 class="master-view-title">Thoughts of open science</h2>
    <div class="container">
      <div class="row">
        <div class="col-lg-6 d-flex flex-column">
          <div class="msg-box">
            <h3 class="msg-title">“Open Science : an evolving ecosystem”</h3>
            <p class="msg-content">
              OS is not an end in itself，but a means towards fairer, more equitable, diverse and inclusive research systems，better geared towards the production, dissemination and use of scientific knowledge，that helps address societal challenges with benefits for all.
            </p>
          </div>
          <div class="user-box">
            <div class="avatar">
              <img src="./img/left-user-avatar.png" alt="" />
            </div>
            <div class="user-info">
              <h3 class="user-name">Ana María Cetto</h3>
              <p class="user-remarks">
                President of the UNESCO Open Science Steering Committee
              </p>
            </div>
            <div class="clear"></div>
          </div>
        </div>
        <div class="col-lg-6 d-flex flex-column">
          <div class="msg-box">
            <h3 class="msg-title">“A Global Consensus”</h3>
            <p class="msg-content">
              Openness is one of the essential attributes of science. At
              present, the open science movement has reached a global consensus,
              and the global open science governance calls for China to join.
              <br />
              <br />
            </p>
          </div>
          <div class="user-box">
            <div class="avatar">
              <img src="./img/right-user-avatar.png" alt="" />
            </div>
            <div class="user-info">
              <h3 class="user-name">Wei Yang</h3>
              <p class="user-remarks">Chinese Academy of Sciences</p>
            </div>
            <div class="clear"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeBanner",
  components: {},
};
</script>
<style lang="scss" scoped>
.master-view {
  background-color: #e8ecf9;
  padding-bottom: 100px;
  &-title {
    padding: 84px 0;
    line-height: 58px;
    font-size: 48px;
    color: #2e4fff;
    font-weight: bold;
  }
  &-container {
    max-width: 1296px;
    margin: 0 auto;
  }
}
.msg-box {
  position: relative;
  padding: 30px 50px;
  padding-bottom: 0;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #fff;
  .msg-title {
    line-height: 28px;
    font-size: 20px;
    font-weight: bold;
    color: #2e4fff;
  }
  .msg-content {
    padding-top: 5px;
    line-height: 28px;
    font-size: 18px;
    color: rgba(18, 20, 29, 0.7);
  }
  ::after {
    position: absolute;
    content: " ";
    height: 20px;
    width: 20px;
    background-color: #fff;
    bottom: -10px;
    left: 50%;
    margin-left: -13px;
    transform: rotate(45deg);
  }
}

.user-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 15%;
  .avatar {
    float: left;
    img {
      display: inline-block;
      width: 62px;
      height: 62px;
    }
  }
  .user-info {
    float: left;
    padding-top: 2px;
    padding-left: 15px;
    .user-name {
      margin: 0;
      line-height: 33px;
      font-size: 20px;
      font-weight: 500;
      text-align: left;
      color: #12141d;
    }
    .user-remarks {
      margin: 0;
      line-height: 27px;
      font-size: 16px;
      text-align: left;
      color: rgba(18, 20, 29, 0.5);
    }
  }
}
.clear {
  clear: both;
}
</style>
