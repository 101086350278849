<template>
  <span>
    <!-- <img src="./logo.png" alt="zero2x Logo" class="logo" height="30" /> -->
    <span class="logo-text">zero2x</span>
  </span>
</template>

<script>
export default {
  name: "LogoText",
  components: {},
};
</script>
<style lang="scss" scoped>
.logo {
  height: 23px;
  width: 57px;
}
.logo-text {
  position: relative;
  top: 7px;
  left: 10px;
  font-size: 23px;
  font-weight: bold;
  color: #051fa9;
}
</style>
