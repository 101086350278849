<template>
  <div style="background-color: #F9FAF9; padding: 100px 0; text-align: left">
    <div class="container">
      <div class="row">
        <div class="col-md-4" style="padding: 0 22px">
          <img :src="img1" alt="" style="width: 90px; margin-bottom: 10px">
          <div
            style="height: calc(100% - 100px); display: flex; flex-direction: column; justify-content: space-between;">
            <p class="text-28 font-weight-bold">
              {{ $t('home.ai.title') }}
            </p>
            <p class="text-18 tc-b7">
              {{ $t('home.ai.desc') }}
            </p>
          </div>
        </div>
        <div class="col-md-4" style="padding: 0 22px">
          <img :src="img2" alt="" style="width: 90px; margin-bottom: 10px">
          <div
            style="height: calc(100% - 100px); display: flex; flex-direction: column; justify-content: space-between;">
            <p class="text-28 font-weight-bold">
              {{ $t('home.collaboration.title') }}
            </p>
            <p class="text-18 tc-b7">
              {{ $t('home.collaboration.desc') }}
            </p>
          </div>
        </div>
        <div class="col-md-4" style="padding: 0 22px">
          <img :src="img3" alt="" style="width: 90px; margin-bottom: 10px">
          <div
            style="height: calc(100% - 100px); display: flex; flex-direction: column; justify-content: space-between;">
            <p class="text-28 font-weight-bold">
              {{ $t('home.science.title') }}
            </p>
            <p class="text-18 tc-b7">
              {{ $t('home.science.desc') }}
            </p>
          </div>
        </div>
      </div>

      <div style="padding: 180px 0 150px">
        <p class="text-48 font-weight-bold" style="text-align: center; margin-bottom: 36px; color: #0F265D">
          {{ $t('home.exploreTheZero2x') }}
        </p>

        <div class="row">
          <div class="col-md-6">
            <img :src="img4" alt="" style="width: 100%; border-radius: 10px">
            <div class="text-24" style="height: 77px; background-color: #55555590; position: relative; top: -120px; color: #fff; display: flex; align-items: center; justify-content: center">
              {{ $t('home.exploreImg1')}}
            </div>
          </div>
          <div class="col-md-6">
            <img :src="img5" alt="" style="width: 100%; border-radius: 10px">
            <div class="text-24" style="height: 77px; background-color: #55555590; position: relative; top: -120px; color: #fff; display: flex; align-items: center; justify-content: center">
              {{ $t('home.exploreImg2')}}
            </div>
          </div>
          <!-- <div class="col-md-6" style="margin-top: 30px">
            <img :src="img6" alt="" style="width: calc(100% + 120px); margin: -60px;">
          </div>
          <div class="col-md-6" style="margin-top: 30px">
            <img :src="img7" alt="" style="width: calc(100% + 120px); margin: -60px;">
          </div> -->
        </div>
      </div>
    </div>

    <div class="bg-image">
      <p class="text-45 font-weight-bold">{{ $t('home.availableOpenResources') }}</p>
      <p class="text-24">{{ $t('home.ourPlatform') }}</p>

      <div style="position: relative; height: 50px">
        <div class="container" style="position: relative; margin-top: 100px;">
          <div class="row"
               style="margin: 0 50px; background-color: #fff; box-shadow: 2px 3px 20px #ccc; border-radius: 10px;">
            <div class="col-md-3" style="display: flex; flex-direction: column; align-items: center; padding: 30px 0"
                 v-for="(item, index) in list" :key="index"
                 :style="index === 0 ? {} : { borderLeft: '1px solid #ccc' }">
              <img :src="item.img" alt="" style="width: 82px; height: 82px; margin-bottom: 30px">
              <span class="text-26">{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="height: 130px" />
  </div>
</template>

<script>
import img2 from "@/views/home/img/items/img.png";
import img1 from "@/views/home/img/items/img_1.png";
import img3 from "@/views/home/img/items/img_2.png";
import img4 from "@/views/home/img/items/img_4.png";
import img5 from "@/views/home/img/items/img_5.png";
import img6 from "@/views/home/img/items/img_6.png";
import img7 from "@/views/home/img/items/img_7.png";
import img8 from "@/views/home/img/items/img_8.png";
import img9 from "@/views/home/img/items/img_9.png";
import img10 from "@/views/home/img/items/img_10.png";
import img11 from "@/views/home/img/items/img_11.png";
import i18n from "@/i18n";

export default {
  name: "HomeItems",
  data() {
    return {
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      img7,
      list: [
        { title: i18n.t('home.computing'), img: img8 },
        { title: i18n.t('home.data'), img: img9 },
        { title:i18n.t('home.models'), img: img10 },
        { title:i18n.t('home.tools'), img: img11 }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.bg-image {
  color: #0F265D;
  height: 450px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-image: url('@/views/home/img/items/img_3.png');
}
</style>
