<template>
  <div class="home-container">
    <home-banner></home-banner>
    <home-items></home-items>
    <home-slide v-if="false"></home-slide>
    <home-master-view v-if="false"></home-master-view>
    <home-research-items v-if="false"></home-research-items>
    <home-activity-items></home-activity-items>
    <home-coporation-items></home-coporation-items>
  </div>
</template>

<script>
import HomeBanner from "@/views/home/HomeBanner.vue";
import HomeSlide from "@/views/home/HomeSlide.vue";
import HomeMasterView from "@/views/home/HomeMasterView.vue";
import HomeResearchItems from "@/views/home/HomeResearchItems.vue";
import HomeActivityItems from "@/views/home/HomeActivityItems.vue";
import HomeCoporationItems from "@/views/home/HomeCoporationItems.vue";
import HomeItems from "@/views/home/HomeItems.vue"

export default {
  name: "HomeView",
  components: {
    HomeBanner,
    HomeSlide,
    HomeMasterView,
    HomeResearchItems,
    HomeActivityItems,
    HomeCoporationItems,
    HomeItems
  },
};
</script>
<style lang="scss" scoped>
.home-container {
  background-color: #fafafa;
}
</style>
