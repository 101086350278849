<template>
  <div class="slide">
    <h2 class="slide-title">Events Now</h2>
    <div class="slide-container container">
      <swiper :options="swiperOption" ref="swiperRef">
        <swiper-slide>
          <b-row>
            <b-col xl="7">
              <img class="slider-img" src="./img/swiper_img/2.png" alt="" />
            </b-col>
            <b-col xl="5" class="right-col">
              <h3 @click="openDetail('https://www.spacecomputing.org/')" class="title cursor-pointer">Wisdom makes the sky and counts the future</h3>
              <p class="description">
                In order to strengthen exchanges and cooperation in the field of
                space computing, gather global forces, help the development of
                the space information industry, and lead the new paradigm of
                global science and technology governance, Zhijiang Laboratory
                and ecological partners jointly initiated the establishment of
                the world's first professional international cooperation
                organization focusing on space computing - Space Computing
                International Organization.
              </p>
            </b-col>
          </b-row>
        </swiper-slide>
        <swiper-slide>
          <b-row>
            <b-col xl="7">
              <img class="slider-img" src="./img/swiper_img/3.png" alt="" />
            </b-col>
            <b-col xl="5" class="right-col">
              <h3 @click="openDetail('http://authtest.zero2x.org.cn/oauth2/authorize?response_type=code&client_id=GeoGPT-TEST&scope=openid,userinfo&redirect_uri=http://test-geogpt.zhejianglab.cn/universal-login/jump/zero2x')" class="title cursor-pointer">
                An Open-Source and Non-Profit Exploratory Research Project
              </h3>
              <p class="description">
                GeoGPT is an artificial intelligence assistant which integrates
                large language models (LLM) with geospatial data. Its goal is to
                enhance user experience through the powerful natural language
                understanding capabilities of LLM.
              </p>
              <b-row class="inner-row" style="padding-top: 40px">
                <b-col>
                  <img
                    src="./img/swiper_img/txt_1.png"
                    alt=""
                    style="width: 467px; height: auto"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </swiper-slide>
        <!--        <swiper-slide>-->
        <!--          <b-row>-->
        <!--            <b-col>-->
        <!--              <img-->
        <!--                class="slider-img"-->
        <!--                src="img/swiper_img/slide_demo.png"-->
        <!--                alt=""-->
        <!--              />-->
        <!--            </b-col>-->
        <!--            <b-col class="right-col">-->
        <!--              <h3 class="title">-->
        <!--                <i>-->
        <!--                  The platform is designed-->
        <!--                  <br />-->
        <!--                  to raise your experience-->
        <!--                </i>-->
        <!--              </h3>-->
        <!--              <p class="description">-->
        <!--                Workplace productivity relates to the amount of work-->
        <!--                <br />-->
        <!--                that your staff can produce over a certain period. A-->
        <!--                <br />-->
        <!--                productive workplace more engaged employees and-->
        <!--                <br />-->
        <!--                improved performance metrics.-->
        <!--              </p>-->
        <!--              <b-row class="inner-row">-->
        <!--                <b-col>-->
        <!--                  <p class="count-line">1.5M</p>-->
        <!--                  <p class="name-line">Active Customers</p>-->
        <!--                </b-col>-->
        <!--                <b-col>-->
        <!--                  <p class="count-line">4.8/5</p>-->
        <!--                  <p class="name-line">Ratings on TrustPilot</p>-->
        <!--                </b-col>-->
        <!--              </b-row>-->
        <!--            </b-col>-->
        <!--          </b-row>-->
        <!--        </swiper-slide>-->
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "HomeBanner",
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 6000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods: {
    openDetail(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.slide {
  padding-bottom: 110px;
  background: #faf9fa;
}
.slide-title {
  margin-top: 130px;
  font-family: Plus Jakarta Sans;
  line-height: 58px;
  font-size: 48px;
  font-weight: bold;
  color: #12141d;
}
.slide-container {
  padding-top: 120px;

  .slider-img {
    position: relative;
    width: 100%;
    height: auto;
    right: -10px;
  }
  .right-col {
    width: 100%;
    box-sizing: border-box;
  }
  .title {
    position: relative;
    font-family: Plus Jakarta Sans;
    font-size: 42px;
    font-weight: 500;
    color: #12141d;
    line-height: 48px;
    text-align: left;
  }
  .description {
    margin: 0;
    padding-top: 15px;
    line-height: 24px;
    font-size: 16px;
    text-align: left;
    color: rgba(18, 20, 29, 0.7);
  }

  :deep(.swiper-pagination) {
    width: 300px;
    bottom: 70px;
    left: 50%;
    margin-left: -30px;
    .swiper-pagination-bullet {
      width: 10px;
      height: 4px;
      border-radius: 2px;
      background: #dae0f7;
      opacity: 1;
      transition: width 0.3s;
      &.swiper-pagination-bullet-active {
        width: 16px;
        background: #2e4fff;
      }
    }
  }
}
.inner-row {
  padding-top: 90px;
  .col {
    text-align: left;
  }
  p {
    margin: 0;
  }
  .count-line {
    font-size: 52px;
    line-height: 48px;
    font-weight: 900;
    color: #2e4fff;
  }
  .name-line {
    position: relative;
    line-height: 24px;
    font-size: 16px;
    padding-top: 5px;
    color: #12141d;
  }
}
</style>
