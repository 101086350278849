import { render, staticRenderFns } from "./HomeResearchItems.vue?vue&type=template&id=599982de&scoped=true"
import script from "./HomeResearchItems.vue?vue&type=script&lang=js"
export * from "./HomeResearchItems.vue?vue&type=script&lang=js"
import style0 from "./HomeResearchItems.vue?vue&type=style&index=0&id=599982de&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../143/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "599982de",
  null
  
)

export default component.exports