<template>
  <div class="research-container mt-5">
    <div class="container research-wrapper">
      <div class="text-center mb-4">
        <h2 class="title">Open Science of zero2x</h2>
        <p class="description" style="max-width: 500px">
          We uphold the four key elements of scientific research, putting the concept of open science into practice through our actions, and supporting AI for Science
        </p>
      </div>
      <b-row class="card-box">
        <b-col lg="6">
          <div class="card">
            <img src="./img/research-icon/1.svg" alt="" />
            <h3 class="card-title">Open Science Data</h3>
            <p class="card-content">
              As a core element of open science, scientific data is a
              fundamental strategic resource for national scientific and
              technological innovation development and socioeconomic
              development.
            </p>
          </div>
        </b-col>
        <b-col lg="6">
          <div class="card">
            <img src="./img/research-icon/2.png" alt="" />
            <h3 class="card-title">Open Science Computing</h3>
            <p class="card-content">
              Technological development has placed computing at the core of
              innovation capacity building, making computational power one of
              the most crucial resources supporting scientific research.
            </p>
          </div>
        </b-col>
        <b-col lg="6">
          <div class="card">
            <img src="./img/research-icon/3.png" alt="" />
            <h3 class="card-title">Open Science Models</h3>
            <p class="card-content">
              With the rise of the 'AI for Science' concept, researchers,
              leveraging AI algorithms for modeling and analysis, can
              significantly improve the accuracy and efficiency of simulations
              based on first principles.
            </p>
          </div>
        </b-col>
        <b-col lg="6">
          <div class="card">
            <img src="./img/research-icon/4.png" alt="" />
            <h3 class="card-title">Open Science Infrastructures</h3>
            <p class="card-content">
              Scientific infrastructure is a critical foundation and key
              platform for accelerating breakthroughs in scientific frontiers
              and addressing major strategic scientific and technological issues
              of the nation.
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "CyberSecurityServices",
};
</script>

<style scoped lang="scss">
.research-container {
  width: 100%;
  height: auto;
  background: #fafafa;
}
.research-wrapper {
  padding-top: 100px;
  padding-bottom: 120px;
  .title {
    line-height: 58px;
    font-size: 48px;
    font-weight: bold;
    font-family: Plus Jakarta Sans;
    color: #12141d;
  }
  .description {
    line-height: 24px;
    font-size: 16px;
    color: rgba(18, 20, 29, 0.7);
  }
  .card {
    margin: 26px 8px 0;
    padding: 30px 45px 0 45px;
    text-align: left;
    background-color: #fff;
    border: none;
    box-shadow: 0 0 6px rgba(32, 31, 34, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    img {
      width: 62px;
      height: auto;
      margin-bottom: 25px;
    }
    .card-title {
      line-height: 28px;
      font-size: 20px;
      font-weight: bold;
      font-family: Space Grotesk;
      color: #12141d;
    }
    .card-content {
      line-height: 28px;
      font-size: 18px;
      color: rgba(18, 20, 29, 0.7);
    }
  }
}
.text-center h2 {
  font-weight: bold;
  font-size: 2rem;
}
.text-center p {
  color: #6c757d;
  margin: 0 auto 50px;
}
.shadow-sm {
  border-radius: 8px;
  overflow: hidden;
  border: none;
}
</style>
