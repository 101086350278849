/**
 * 图中相关国家地区经纬度,根据你的需求添加数据
 * 关于国家的经纬度，可以用首都的经纬度或者其他城市的经纬度
 */
export const geoMap = {
  美国: [-73.97622, 40.757498], // 纽约
  加拿大: [-109.404347, 60.638178],
  澳大利亚: [137.708144, -25.328065],
  中国: [116.512885, 39.847469],
  中国香港: [114.15, 22.15],
  中国澳门: [113.5, 22.2],
  中国台湾: [121.31, 25.03],
  捷克: [116.413384, 39.910925],
  新加坡: [103.413384, 1.910925],
  阿富汗: [69.132386, 34.739287], // 阿富汗
  安哥拉: [13.285773, -8.748538], // 安哥拉
  阿尔巴尼亚: [19.809499, 41.389924], // 阿尔巴尼亚
  阿根廷: [-64.388276, -31.379492], // 阿根廷-科尔多瓦
  阿拉伯联合酋长国: [54.662609, 24.390891], // 阿拉伯联合酋长国-阿布扎比
  奥地利: [16.373532, 48.209137], // 奥地利-维也纳
  阿塞拜疆共和国: [49.867851, 40.409897], // 阿塞拜疆共和国-巴库
  比利时: [4.342799, 50.85052], // 比利时
  贝宁: [2.626232, 6.499347], // 贝宁-波多诺伏
  布基纳法索: [-1.521672, 12.375667], // 布基纳法索-瓦加杜古
  孟加拉人民共和国: [90.410568, 23.813179], // 孟加拉人民共和国 - 达卡
  保加利亚: [23.318705, 42.69792], // 保加利亚-索非亚
  巴哈马: [-73.562575, 40.655271], // 巴哈马-拿骚
  南斯拉夫社会主义联邦共和国: [18.423339, 43.796617], // 黑塞哥维那-萨拉热窝
  白俄罗斯: [27.564451, 53.975825], // 白俄罗斯-明克斯
  伯利兹: [12.849472, -4.571506], // 中美洲国家伯利兹-贝尔莫潘
  百慕大群岛: [-86.054972, 40.038827], // 百慕大群岛-哈密尔顿
  玻利维亚: [-78.332036, 22.082519], // 玻利维亚-哈密尔顿
  巴西: [-47.888777, -15.791724], // 巴西-巴西利亚
  文莱: [114.951828, 4.948475], // 文莱-斯里巴加湾市
  不丹王国: [89.647324, 27.476933], // 不丹王国-廷布
  博茨瓦纳: [25.967078, -24.62583], // 博茨瓦纳-哈博罗内
  中非共和国: [18.557903, 4.396116], // 中非共和国-班吉
  瑞士: [7.44716, 46.950139], // 瑞士-伯尔尼  Switzerland
  智利: [-70.693344, 19.48165], // 智利-圣地亚哥     Chile
  科特迪瓦: [-5.255454, 6.826119], // 亚穆苏克罗-科特迪瓦 Ivory Coast
  喀麦隆: [11.502655, 3.852758], // 喀麦隆-雅温得   Cameroon
  刚果民主共和国: [15.2495, -4.422756], // 刚果民主共和国 -金沙萨   Democratic Republic of the Congo
  刚果共和国: [15.240923, -4.263634], // 刚果共和国-布拉柴维尔   Republic of the Congo
  哥伦比亚共和国: [-74.074104, 4.715315], // 哥伦比亚共和国-波哥大    Colombia
  哥斯达黎加共和国: [-90.871819, 14.023867], // 哥斯达黎加共和国-圣何塞    Costa Rica
  古巴共和国: [-82.366883, 23.119708], // 古巴共和国- 哈瓦那   Cuba
  北塞浦路斯: [33.380839, 35.184858], // 北塞浦路斯土耳其共和国-尼科西亚   Northern Cyprus
  塞浦路斯: [33.380264, 35.183442], // 塞浦路斯-尼科西亚    Cyprus
  捷克共和国: [14.437258, 50.078142], // 捷克共和国-布拉格    Czech Republic
  德国: [13.40498, 52.521199], // 德国-柏林  Germany
  吉布提共和国: [43.146919, 11.573827], // 吉布提- 吉布提市   Djibouti
  丹麦: [12.431735, 55.665973], // 丹麦-哥本哈根  Denmark
  多米尼加共和国: [-80.245123, 22.580836], // 多米尼加共和国-圣多明各  Dominican Republic
  阿尔及利亚: [3.058874, 36.755571], // 阿尔及利亚-阿尔及尔    Algeria
  厄瓜多尔: [-78.467263, -0.179206], // 厄瓜多尔-基多   Ecuador
  埃及: [31.236287, 30.045171], // 埃及-开罗      Egypt
  厄立特里亚: [38.925716, 15.324804], // 厄立特里亚-阿斯马拉  Eritrea
  西班牙: [-3.703414, 40.419467], // 西班牙-马德里 Spain
  爱沙尼亚: [24.75379, 59.439569], // 爱沙尼亚共和国 -塔林        Estonia
  埃塞俄比亚: [38.753839, 8.986474], // 埃塞俄比亚联邦民主共和国-亚的斯亚贝巴  Ethiopia
  芬兰: [24.935506, 60.173495], // 芬兰-赫尔辛基   Finland
  斐济: [178.450079, -18.122335], // 斐济共和国-苏瓦    Fiji
  马尔维纳斯群岛: [-57.940098, -51.615196], // 马尔维纳斯群岛- (阿根廷港)斯坦利   Falkland Islands
  法国: [2.333696, 48.865244], //  法兰西共和国-巴黎   France
  加蓬: [9.495768, 0.436579], // 加蓬-利伯维尔  Gabon
  英国: [-0.130058, 51.508604], // 英国-伦敦     United Kingdom
  格鲁吉亚: [44.827351, 41.71589], // 格鲁吉亚-第比利斯   Georgia
  加纳: [-0.188114, 5.604581], // 加纳-阿克拉    Ghana
  几内亚: [-13.577316, 9.644511], // 几内亚-科纳克里  Guinea
  冈比亚: [-16.582383, 13.457299], // 冈比亚   班珠尔 --Gambia
  几内亚比绍: [-15.619766, 11.885767], // 几内亚比绍 -比绍    Guinea Bissau
  赤道几内亚: [8.736346, 3.749414], // 赤道几内亚   -马拉博     Equatorial Guinea
  希腊: [23.72821, 37.9846], // 希腊-雅典   Greece
  格陵兰: [-51.573608, 64.213084], // 格陵兰-努克   Greenland
  危地马拉: [-90.543513, 14.649067], // 危地马拉-危地马拉城   Guatemala
  圭亚那: [-58.140752, 6.803865], // 圭亚那-乔治敦  French Guiana
  圭亚那合作共和国: [-58.149951, 6.81306], // 圭亚那合作共和国    Guyana
  洪都拉斯共和国: [-87.253756, 14.044503], // 洪都拉斯共和国 -特古西加尔巴  Honduras
  克罗地亚: [15.974196, 45.812166], // 克罗地亚-萨格勒布  Croatia
  海地: [-72.307002, 18.595629], // 海地-太子港    Haiti
  匈牙利: [19.04196, 47.498109], // 匈牙利-布达佩斯    Hungary
  印度尼西亚: [106.870937, -6.240893], // 印度尼西亚-雅加达   Indonesia
  印度: [77.206503, 28.62928], // 印度  -新德里   India
  爱尔兰: [-6.259735, 53.350324], // 爱尔兰共和国-都柏林   Ireland
  伊朗: [51.388399, 35.6899], // 伊朗-德黑兰   Iran
  伊拉克: [44.363655, 33.314606], // 伊拉克-巴格达   Iraq
  冰岛: [-21.859781, 64.099918], // 冰岛共和国-雷克雅未克   Iceland
  以色列: [35.21141, 31.77102], // 以色列-耶路撒冷   Israel
  意大利: [12.491267, 41.904612], // 意大利 -罗马  Italy
  牙买加: [140.433549, -36.466221], // 牙买加-金斯敦  Jamaica
  约旦: [35.927003, 31.946341], // 约旦-安曼     Jordan
  日本: [139.713657, 35.707004], // 日本-东京   Japan
  哈萨克斯坦: [71.470572, 51.160655], // 哈萨克斯坦-阿斯塔纳   Kazakhstan
  肯尼亚: [36.820997, -1.291346], // 肯尼亚-内罗毕   Kenya
  吉尔吉斯: [74.569345, 42.874887], // 吉尔吉斯共和国-比什凯克市   Kyrgyzstan
  柬埔寨: [104.891769, 11.545102], // 柬埔寨-金边  Cambodia
  韩国: [126.986407, 37.536804], // 韩国-首尔   South Korea
  科索沃共和国: [21.163778, 42.663444], // 科索沃共和国-普里什蒂纳   Kosovo
  科威特: [47.977439, 29.376031], // 科威特-科威特城 Kuwait
  老挝: [102.632451, 17.975794], // 老挝-万象  Laos
  黎巴嫩: [35.500052, 33.89439], // 黎巴嫩-贝鲁特  Lebanon
  利比里亚: [-10.761016, 6.291152], // 利比里亚-蒙罗维亚
  利比亚: [13.19135, 32.887237], // 利比亚-的黎波里   Libya
  斯里兰卡: [79.865104, 6.909415], // 斯里兰卡-科伦坡  Sri Lanka
  莱索托: [29.085487, -29.04088], // 莱索托-马塞卢   Lesotho
  立陶宛: [25.279069, 54.687457], // 立陶宛-维尔纽斯  Lithuania
  卢森堡: [6.131308, 49.613029], // 卢森堡-卢森堡市 Luxembourg
  拉脱维亚: [24.105101, 56.949378], // 拉脱维亚-里加 Latvia
  摩洛哥: [-6.862605, 33.977609], // 摩洛哥-拉巴特 Morocco
  摩尔多瓦: [28.858505, 47.012344], // 摩尔多瓦 -基希讷乌Moldova
  马达加斯加: [47.508608, -18.867016], // 马达加斯加-塔那那利佛 Madagascar
  墨西哥: [-99.134543, 19.433793], // 墨西哥   Mexico
  马其顿共和国: [21.427289, 41.997997], // 马其顿共和国-斯科普里   Macedonia
  马里共和国: [-8.003581, 12.640388], // 马里共和国 -巴马科   Mali
  缅甸: [96.077119, 19.763943], // 缅甸-内比都   Myanmar
  黑山共和国: [19.261583, 42.435497], //  黑山共和国-波德戈里察  Montenegro
  蒙古: [106.908677, 47.915632], // 蒙古-乌兰巴托  Mongolia
  莫桑比克: [32.602247, -25.890353], // 莫桑比克-马普托   Mozambique
  毛里塔尼亚: [-15.964452, 18.0745], // 毛里塔尼亚-努瓦克肖特   Mauritania
  马拉维: [33.774057, -13.961542], // 马拉维-利隆圭   Malawi
  马来西亚: [101.681865, 3.136134], // 马来西亚-吉隆坡  Malaysia
  纳米比亚: [17.034567, -22.555179], // 纳米比亚-温得和克   Namibia
  新喀里多尼亚: [166.449312, -22.251664], // 新喀里多尼亚-努美阿   New Caledonia
  尼日尔: [2.118056, 13.516354], // 尼日尔共和国-尼亚美   Niger
  尼日利亚: [7.459498, 9.126437], // 尼日利亚-阿布贾   Nigeria
  尼加拉瓜共和国: [-86.236749, 12.115559], // 尼加拉瓜共和国-马那瓜  Nicaragua
  荷兰: [4.89354, 52.370649], // 荷兰-阿姆斯特丹   Netherlands
  挪威: [4.89354, 52.370649], // 挪威-奥斯陆   Norway
  尼泊尔: [85.320511, 27.711], // 尼泊尔-加德满都   Nepal
  新西兰: [174.761827, -41.291661], // 新西兰-惠灵顿   New Zealand
  阿曼: [58.405562, 23.587133], // 阿曼-马斯喀特   Oman
  巴基斯坦伊斯兰: [73.076186, 33.714975], // 巴基斯坦伊斯兰共和国-伊斯兰堡   Pakistan
  巴拿马: [-79.401974, 9.102657], // 巴拿马-巴拿马城   Panama
  秘鲁: [-77.042548, -12.044223], // 秘鲁-利马  Peru
  菲律宾: [120.985715, 14.603576], // 菲律宾-马尼拉  Philippines
  巴布亚新几内亚: [147.27892, -9.465948], // 巴布亚新几内亚-莫尔兹比港  Papua New Guinea
  波兰: [21.021333, 52.232842], // 波兰-华沙   Poland
  波多黎各: [120.809359, 17.750942], // 波多黎各-圣胡安   Puerto Rico
  北朝鲜: [125.760512, 39.041685], // 北朝鲜-平壤   North Korea
  葡萄牙: [-9.161365, 38.768409], // 葡萄牙-里斯本   Portugal
  巴拉圭: [-57.576213, -25.260342], // 巴拉圭-亚松森   Paraguay
  卡塔尔: [51.531551, 25.286355], // 卡塔尔-多哈   Qatar
  罗马尼亚: [51.531551, 25.286355], // 罗马尼亚-布加勒斯特   Romania
  俄罗斯: [37.621612, 55.758257], // 俄罗斯联邦-莫斯科   Russia
  卢旺达: [30.102346, -1.969667], // 卢旺达-基加利   Rwanda
  西撒哈拉: [-13.233061, 27.246935], // 西撒哈拉-阿尤恩   Western Sahara
  沙特阿拉伯: [46.672152, 24.714184], // 沙特阿拉伯 -利雅得  Saudi Arabia
  南苏丹: [31.586576, 4.891962], // 南苏丹-朱巴   Sudan South
  苏丹: [32.560519, 15.501806], // 苏丹-喀土穆   Sudan
  塞内加尔: [-17.367747, 14.764454], // 塞内加尔-达喀尔   Senegal
  所罗门群岛: [159.981803, -9.43537], // 所罗门-霍尼亚拉   Solomon Islands
  塞拉利昂: [-13.233425, 8.466744], // 塞拉利昂-弗里敦   Sierra Leone
  萨尔瓦多: [-89.22758, 13.692121], // 萨尔瓦多-圣萨尔瓦多   El Salvador
  索马里兰: [44.088511, 9.562318], // 索马里兰-哈尔格萨   Somaliland
  索马里: [45.317204, 2.047013], // 索马里-摩加迪沙   Somalia
  塞尔维亚: [20.446144, 44.786894], // 塞尔维亚-贝尔格莱德   Republic of Serbia
  苏里南共和国: [-55.206128, 5.853474], // 苏里南共和国-帕拉马里博   Suriname
  斯洛伐克共和国: [17.099623, 48.141697], // 斯洛伐克共和国-布拉迪斯拉发   Slovakia
  瑞典: [18.058376, 59.327783], // 瑞典- 斯德哥尔摩 Sweden
  斯威士兰: [31.134648, -26.292261], // 斯威士兰-姆巴巴内   Swaziland
  叙利亚: [36.274228, 33.521994], // 叙利亚-大马士革   Syria
  乍得: [15.058995, 12.144253], // 乍得-恩贾梅纳   Chad
  多哥: [1.244889, 6.219695], // 多哥- 洛美   Togo
  泰国: [100.451117, 13.724061], // 泰国-曼谷   Thailand
  塔吉克斯坦: [68.788383, 38.572383], // 塔吉克斯坦-杜尚别   Tajikistan
  土库曼斯坦: [58.338576, 38.057635], // 土库曼斯坦-阿什哈巴德市   Turkmenistan
  东帝汶: [125.564669, -8.549475], // 东帝汶-帝力   East Timor
  特立尼达和多巴哥: [-61.528354, 10.674498], // 特立尼达和多巴哥-西班牙港   Trinidad and Tobago
  突尼斯: [10.208651, 36.866028], // 突尼斯-突尼斯市   Tunisia
  土耳其: [32.853705, 39.941552], // 土耳其-安卡拉   Turkey
  坦桑尼亚: [35.698823, -6.211186], // 坦桑尼亚联合共和国-多多马    United Republic of Tanzania
  乌干达: [32.581738, 0.352894], // 乌干达-坎帕拉   Uganda
  乌克兰: [30.512697, 50.452995], // 乌克兰-基辅   Ukraine
  乌拉圭: [-56.163094, -34.893771], // 乌拉圭-蒙得维的亚  Uruguay
  乌兹别克斯: [69.247259, 41.313586], // 乌兹别克斯-塔什干市   Uzbekistan
  委内瑞拉: [-66.90346, 10.492688], // 委内瑞拉-加拉加斯   Venezuela
  越南: [105.699615, 20.973967], // 越南-河内  Vietnam
  瓦努阿图: [168.327477, -17.730243], // 瓦努阿图-维拉港   Vanuatu
  也门: [44.188624, 15.375985], // 也门-萨那  Yemen
  南非共和国: [18.480216, -33.873348], // 南非共和国- 开普敦  South Africa
  赞比亚: [28.321954, -15.376648], // 赞比亚-卢萨卡   Zambia
  津巴布韦: [31.033189, -17.824573], // 津巴布韦-哈拉雷   Zimbabwe
};

export const nameMap = {
  Afghanistan: "阿富汗",
  Albania: "阿尔巴尼亚",
  Algeria: "阿尔及利亚",
  Angola: "安哥拉",
  Argentina: "阿根廷",
  Armenia: "亚美尼亚",
  Australia: "澳大利亚",
  Austria: "奥地利",
  Azerbaijan: "阿塞拜疆",
  Bahamas: "巴哈马",
  Bahrain: "巴林",
  Bangladesh: "孟加拉国",
  Belarus: "白俄罗斯",
  Belgium: "比利时",
  Belize: "伯利兹",
  Benin: "贝宁",
  Bhutan: "不丹",
  Bolivia: "玻利维亚",
  "Bosnia and Herz.": "波斯尼亚和黑塞哥维那",
  Botswana: "博茨瓦纳",
  Brazil: "巴西",
  "British Virgin Islands": "英属维京群岛",
  Brunei: "文莱",
  Bulgaria: "保加利亚",
  "Burkina Faso": "布基纳法索",
  Burundi: "布隆迪",
  Cambodia: "柬埔寨",
  Cameroon: "喀麦隆",
  Canada: "加拿大",
  "Cape Verde": "佛得角",
  "Cayman Islands": "开曼群岛",
  "Central African Rep.": "中非共和国",
  Chad: "乍得",
  Chile: "智利",
  China: "中国",
  Colombia: "哥伦比亚",
  Comoros: "科摩罗",
  Congo: "刚果",
  "Costa Rica": "哥斯达黎加",
  Croatia: "克罗地亚",
  Cuba: "古巴",
  Cyprus: "塞浦路斯",
  "Czech Rep.": "捷克共和国",
  "Côte d'Ivoire": "科特迪瓦",
  "Dem. Rep. Congo": "刚果民主共和国",
  "Dem. Rep. Korea": "朝鲜",
  Denmark: "丹麦",
  Djibouti: "吉布提",
  "Dominican Rep.": "多米尼加共和国",
  Ecuador: "厄瓜多尔",
  Egypt: "埃及",
  "El Salvador": "萨尔瓦多",
  "Equatorial Guinea": "赤道几内亚",
  Eritrea: "厄立特里亚",
  Estonia: "爱沙尼亚",
  Ethiopia: "埃塞俄比亚",
  "Falkland Is.": "福克兰群岛",
  Fiji: "斐济",
  Finland: "芬兰",
  "Fr. S. Antarctic Lands": "所罗门群岛",
  France: "法国",
  Gabon: "加蓬",
  Gambia: "冈比亚",
  Georgia: "格鲁吉亚",
  Germany: "德国",
  Ghana: "加纳",
  Greece: "希腊",
  Greenland: "格陵兰",
  Guatemala: "危地马拉",
  Guinea: "几内亚",
  "Guinea-Bissau": "几内亚比绍",
  Guyana: "圭亚那",
  Haiti: "海地",
  Honduras: "洪都拉斯",
  Hungary: "匈牙利",
  Iceland: "冰岛",
  India: "印度",
  Indonesia: "印度尼西亚",
  Iran: "伊朗",
  Iraq: "伊拉克",
  Ireland: "爱尔兰",
  "Isle of Man": "马恩岛",
  Israel: "以色列",
  Italy: "意大利",
  Jamaica: "牙买加",
  Japan: "日本",
  Jordan: "约旦",
  Kazakhstan: "哈萨克斯坦",
  Kenya: "肯尼亚",
  Korea: "韩国",
  Kuwait: "科威特",
  Kyrgyzstan: "吉尔吉斯斯坦",
  "Lao PDR": "老挝",
  Latvia: "拉脱维亚",
  Lebanon: "黎巴嫩",
  Lesotho: "莱索托",
  Liberia: "利比里亚",
  Libya: "利比亚",
  Lithuania: "立陶宛",
  Luxembourg: "卢森堡",
  Macedonia: "马其顿",
  Madagascar: "马达加斯加",
  Malawi: "马拉维",
  Malaysia: "马来西亚",
  Maldives: "马尔代夫",
  Mali: "马里",
  Malta: "马耳他",
  Mauritania: "毛利塔尼亚",
  Mauritius: "毛里求斯",
  Mexico: "墨西哥",
  Moldova: "摩尔多瓦",
  Monaco: "摩纳哥",
  Mongolia: "蒙古",
  Montenegro: "黑山共和国",
  Morocco: "摩洛哥",
  Mozambique: "莫桑比克",
  Myanmar: "缅甸",
  Namibia: "纳米比亚",
  Nepal: "尼泊尔",
  Netherlands: "荷兰",
  "New Caledonia": "新喀里多尼亚",
  "New Zealand": "新西兰",
  Nicaragua: "尼加拉瓜",
  Niger: "尼日尔",
  Nigeria: "尼日利亚",
  Norway: "挪威",
  Oman: "阿曼",
  Pakistan: "巴基斯坦",
  Panama: "巴拿马",
  "Papua New Guinea": "巴布亚新几内亚",
  Paraguay: "巴拉圭",
  Peru: "秘鲁",
  Philippines: "菲律宾",
  Poland: "波兰",
  Portugal: "葡萄牙",
  "Puerto Rico": "波多黎各",
  Qatar: "卡塔尔",
  Reunion: "留尼旺",
  Romania: "罗马尼亚",
  Russia: "俄罗斯联邦",
  Rwanda: "卢旺达",
  "S. Geo. and S. Sandw. Is.": "南乔治亚和南桑威奇群岛",
  "S. Sudan": "南苏丹",
  "San Marino": "圣马力诺",
  "Saudi Arabia": "沙特阿拉伯",
  Senegal: "塞内加尔",
  Serbia: "塞尔维亚",
  "Sierra Leone": "塞拉利昂",
  Singapore: "新加坡",
  Slovakia: "斯洛伐克",
  Slovenia: "斯洛文尼亚",
  "Solomon Is.": "所罗门群岛",
  Somalia: "索马里",
  "South Africa": "南非",
  Spain: "西班牙",
  "Sri Lanka": "斯里兰卡",
  Sudan: "苏丹",
  Suriname: "苏里南",
  Swaziland: "斯威士兰",
  Sweden: "瑞典",
  Switzerland: "瑞士",
  Syria: "叙利亚",
  Tajikistan: "塔吉克斯坦",
  Tanzania: "坦桑尼亚",
  Thailand: "泰国",
  Togo: "多哥",
  Tonga: "汤加",
  "Trinidad and Tobago": "特立尼达和多巴哥",
  Tunisia: "突尼斯",
  Turkey: "土耳其",
  Turkmenistan: "土库曼斯坦",
  "U.S. Virgin Islands": "美属维尔京群岛",
  Uganda: "乌干达",
  Ukraine: "乌克兰",
  "United Arab Emirates": "阿拉伯联合酋长国",
  "United Kingdom": "英国",
  "United States": "美国",
  Uruguay: "乌拉圭",
  Uzbekistan: "乌兹别克斯坦",
  Vanuatu: "瓦努阿图",
  "Vatican City": "梵蒂冈城",
  Venezuela: "委内瑞拉",
  Vietnam: "越南",
  "W. Sahara": "西撒哈拉",
  Yemen: "也门",
  Yugoslavia: "南斯拉夫",
  Zaire: "扎伊尔",
  Zambia: "赞比亚",
  Zimbabwe: "津巴布韦",
  Taiwan: "中国台湾",
  "Hong Kong": "中国香港",
  Macao: "中国澳门",
};
