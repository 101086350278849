<template>
  <b-navbar
    toggleable="lg"
    type="light"
    variant="white"
    class="custom-navbar sticky-top"
  >
    <a href="/" class="logo-link">
      <logo-text></logo-text>
    </a>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="mx-auto">
        <b-nav-item v-if="!isHiden">
          <router-link to="/" exact>{{ $t('common.home') }}</router-link>
        </b-nav-item>
        <b-nav-item v-if="!isHiden">
          <router-link to="/about-us">{{ $t('common.aboutUs') }}</router-link>
        </b-nav-item>
        <b-nav-item v-if="!isHiden">
          <router-link to="/projects-programs">
            {{ $t('common.projectsPrograms') }}
          </router-link>
        </b-nav-item>
        <!-- <b-nav-item v-if="!isHiden">
          <a href="javascript:void(0);" class="disabled"> Resource Hub </a>
        </b-nav-item> -->
        <b-nav-item v-if="!isHiden">
          <router-link to="/news-events">{{ $t('common.newsEvents') }}</router-link>
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav >
        <img v-if="false" class="lang-img lang-item" src="./icon/lang.svg" alt="" />
        <b-nav-item-dropdown v-if="false" :text="langMap[getLanguage()]" right class="lang-item">
          <template v-for="(label, lang, index) in langMap">
            <b-dropdown-item v-if="getLanguage() !== lang" :key="index" href="#" @click="setLanguage(lang)">{{ label }}</b-dropdown-item>
          </template>
        </b-nav-item-dropdown>
        <login-bar></login-bar>
        <!--        <button class="sign-in-btn" style="display: none">Sign in</button>-->
        <!--        &lt;!&ndash;        <img class="lang-img user-item" src="./icon/lang.svg" alt="" />&ndash;&gt;-->
        <!--        <b-icon class="lang-img user-item" icon="person-fill"></b-icon>-->
        <!--        <b-nav-item-dropdown text="English" right class="user-item">-->
        <!--          <b-dropdown-item href="#">退出</b-dropdown-item>-->
        <!--        </b-nav-item-dropdown>-->
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import LogoText from "@/views/common/LogoText.vue";
import LoginBar from "@/views/common/LoginBar.vue";
import { getLanguage, setLanguage} from "@/i18n"

export default {
  name: "CommonHeader",
  components: { LogoText, LoginBar },
  // watch: {
  //   "$route": function (value) {
  //     console.log(value.query.isHidenNav);
  //     this.isHiden = value.query.isHidenNav === 'true';
  //     console.log(this.isHiden);
  //   }
  // },
  data() {
    return {
      isHiden: false,
      langMap: {
        zh: '简体中文',
        ja: '日本語',
        ko: '한국어',
        en: 'English',
        fr: 'Français',
        de: 'Deutsch',
        ru: 'Русский',
        es: 'Español',
        tr: 'Türkçesi',
      },
      getLanguage,
      setLanguage
    };
  },
  methods: {
    setHiden(hidden) {
      this.isHiden = hidden;
    },
  },
};
</script>

<style lang="scss" scoped>
/* 添加以下样式 */
.navbar-expand-lg .navbar-nav.mx-auto {
  flex: 1;
  justify-content: center;
}
.logo-link {
  line-height: 0;
  text-decoration: none;
}

/* 添加以下样式 */
.custom-navbar {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar {
  height: 72px;
  padding: 0 37px;
}
/* ... 其他样式 ... */
.navbar-nav .nav-item a {
  color: inherit;
  text-decoration: none;
}

.navbar-light .navbar-nav .nav-link {
  padding: 0 30px;
}

.navbar-nav .nav-item > a {
  a {
    position: relative;
    display: inline-block;
    padding: 0 5px 4px 5px;
    border-bottom: 4px solid transparent;
    font-size: 16px;
    color: 201F22;
    font-weight: 500;
    &.router-link-active {
      border-color: #2e4fff;
    }
    &::after {
      content: " ";
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 0;
      height: 4px; /* 边框的高度 */
      background-color: #2e4fff; /* 边框的颜色 */
      transition: width 0.3s; /* 动画时间 */
    }

    &:hover::after {
      width: 100%; /* 悬停时填充宽度 */
    }

    &.disabled {
      color: #777f8f;
      &::after {
        left: 8px;
        right: 8px;
        background: #555557;
      }
      &:hover::after {
        width: 100px; /* 悬停时填充宽度 */
      }
    }
  }
}

.navbar-nav {
  align-items: center;
}
.custom-navbar {
  :deep(.nav-link) {
    font-size: 16px;
    color: #525252 !important;
    &:focus-visible {
      outline: none;
    }
  }
  :deep(.dropdown-menu) {
    min-width: 150px;
    height: auto;
    padding: 10px 0;
    border: none;
    box-shadow: 0 0 6px rgba(32, 31, 34, 0.2);
    .dropdown-item {
      display: inline-block;
      width: 100%;
      padding: 0 18px;
      line-height: 40px;
      font-size: 14px;
      color: #52575c;
      box-sizing: border-box;
      &:hover {
        background: #f6faff;
        color: #007eff;
      }
    }
  }
  :deep(.dropdown-menu-right) {
    left: 50%;
    margin-left: -90px;
  }
}
.lang-img {
  display: inline-block;
  width: 20px;
  height: 20px;
}
</style>
