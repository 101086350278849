<template>
  <div id="app">
    <common-header ref="changePasswordView"/>
    <router-view />
    <common-footer></common-footer>
  </div>
</template>
<script>
import CommonHeader from "@/views/common/Header.vue";
import CommonFooter from "@/views/common/Footer.vue";
export default {
  name: "AppView",
  components: {
    CommonHeader,
    CommonFooter,
  },
  watch: {
    $route: function (value) {
      if ((value.name === "ChangePassword" || value.name === "Personal") &&  value.params.isHidenNav === true) {
        this.$refs.changePasswordView.setHiden(true);
      } else {
        this.$refs.changePasswordView.setHiden(false);
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Inter;
  //font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
