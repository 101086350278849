<template>
  <div class="coporation-container">
    <h2 class="title">{{ $t('home.ourPartner') }}</h2>
    <p class="description">
      {{ $t('home.ourPartnerDesc') }}
    </p>

    <div class="container">
      <b-row class="card-box">
        <b-col xl="3" lg="4" md="6">
          <div class="img-box">
            <img src="./img/coporation-icon/1.png" alt="" />
          </div>
        </b-col>
        <b-col xl="3" lg="4" md="6">
          <div class="img-box">
            <img
              src="./img/coporation-icon/2.png"
              style="width: 111px"
              alt=""
            />
          </div>
        </b-col>
        <b-col xl="3" lg="4" md="6">
          <div class="img-box">
            <img src="./img/coporation-icon/3.png" alt="" />
          </div>
        </b-col>
        <b-col xl="3" lg="4" md="6">
          <div class="img-box">
            <img src="./img/coporation-icon/4.jpg" alt="" />
          </div>
        </b-col>
        <b-col xl="3" lg="4" md="6">
          <div class="img-box">
            <img src="./img/coporation-icon/5.png" alt="" />
          </div>
        </b-col>
        <b-col xl="3" lg="4" md="6">
          <div class="img-box">
            <img src="./img/coporation-icon/6.png" alt="" />
          </div>
        </b-col>
        <b-col xl="3" lg="4" md="6">
          <div class="img-box">
            <img src="./img/coporation-icon/7.png" alt="" />
          </div>
        </b-col>
        <b-col xl="3" lg="4" md="6">
          <div class="img-box">
            <img src="./img/coporation-icon/8.png" alt="" />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeCoporationItems",
  components: {},
};
</script>
<style lang="scss" scoped>
.coporation-container {
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 125px;
  background: radial-gradient(circle at center bottom, #d1eef7, #e8ecf7);
  .title {
    line-height: 58px;
    font-size: 48px;
    font-weight: bold;
    font-family: Plus Jakarta Sans;
    color: #12141d;
  }
  .description {
    max-width: 390px;
    margin: 0 auto;
    line-height: 24px;
    font-size: 16px;
    color: rgba(18, 20, 29, 0.7);
  }
  .card-box {
    margin-top: 70px;
    .img-box {
      width: 302px;
      height: 90px;
      margin: 15px auto;
      line-height: 90px;
      text-align: center;
      align-items: center;
      background-color: #fff;
      border-radius: 15px;
      overflow: hidden;
      img {
        width: 169px;
        height: auto;
      }
    }
  }
}
</style>
